/* 1. Components */

@use 'components/example-viewer';
@use 'components/input';

/* 2. Overrides */

@use 'overrides/angular-material';
@use 'overrides/highlightjs';
@use 'overrides/perfect-scrollbar';
@use 'overrides/quill';

/*snack bar styles*/

.mdc-snackbar__label {
    padding: 0px !important;
}

.mdc-snackbar__surface {
    padding: 0px !important;
}

td,
th {
    text-align: center !important;
}